import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import notFoundImage from "../../images/NotFoundPage/404.png";
import { useTranslation } from "react-i18next";
import "./styles.scss";

const NotFoundPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container>
        <Row g={12} xl={12} md={12} sm={12} xs={12}>
          <Col g={12} xl={12} md={12} sm={12} xs={12}>
            <div className="not-found-page-wrapper">
              <img
                className="not-found-page-image"
                src={notFoundImage}
                alt="notFoundImage"
              />
              <span className="not-found-page-text">{t("NotFoundPage.1")}</span>
              <div className="back-to-main">
                <a href="/">{t("NotFoundPage.2")}</a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NotFoundPage;
