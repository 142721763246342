import React from "react";
import Layout from "../components/Common/Layout";
import SEO from "../components/Common/SEO";
import NotFoundPage from "../components/NotFoundPage";

const ErrorPage = () => (
  <Layout>
    <SEO />
    <NotFoundPage />
  </Layout>
)

export default ErrorPage;
